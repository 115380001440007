.index-page-container {
  height: 100dvh;
  //   background-image: url("../../assets/lilith-light-bg-small.webp"); /* Lighted*/
  background-image: url("../../assets/backgrounds/lilith-silhouette-bg-small.webp"); /* Silhouette*/
  background-position: center;
  background-size: cover;
}

.index-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  & * {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
  }
}

.page-header {
  padding-top: 20rem;
  & img {
    width: 50%;
    filter: drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 14px);
  }
}

/* Menu Design */
.menu-container {
  position: relative;
  filter: drop-shadow(rgba(0, 0, 0, 0.8) 0px 3px 3px);
  padding-bottom: 2rem;
  z-index: 0;
  margin-bottom: 2rem;
  width: 498px;
  height: 675px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  & .logo-container {
    z-index: 1;
    position: absolute;
    top: 80px;

    & img {
      width: 750px;
      filter: drop-shadow(rgba(0, 0, 0, 0.3) 2px 5px 8px);
    }
  }

  & .app-title {
    font-size: 2rem;
    text-align: center;
    color: #aaaaaa;
    position: absolute;
    bottom: 200px;

    & h2 {
      background-image: linear-gradient(0deg, #444444, #ffffff);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 100%;
      filter: drop-shadow(rgba(0, 0, 0, 0.8) 0px 2px 5px);
    }
  }

  & a {
    display: inline-block;
    text-decoration: none;
    width: 70%;
    margin-bottom: 0.8rem;
  }

  &::before,
  ::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 0;
  }

  &::before {
    background-image: url("../../assets/frames/gothic-menu-frame-with-skull.webp");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  & .bg-container {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/backgrounds/stone-texture-dark-coarse.webp");
    background-repeat: repeat;
  }
}

@media screen and (min-width: 1981px) {
  .menu-container {
    // margin: 5% auto;
  }
}

/*========================== RESPONSIVE DESIGNS*/
@media screen and (min-width: 1650px) {
  .menu-container {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 1030px) {
  .menu-container {
    height: 70%;
  }
}

@media screen and (max-width: 767px) {
  .page-header {
    display: none;
  }
  .index-content {
    align-items: flex-end;
    justify-content: center;
  }
  .menu-container {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  .menu-container::before {
    background-size: contain;
    background-position: bottom;
  }

  .menu-container .logo-container {
    & img {
      width: 99%;
    }
  }

  .menu-container .app-title {
    font-size: 1.5rem;
  }

  .menu-container a {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .menu-container {
    // width: 70%;
    // height: auto;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  .menu-container .logo-container {
    // top: 60px;

    & img {
      // width: 90%;
    }
  }

  .menu-container .app-title {
    font-size: 1.75rem;
    // bottom: 150px;
  }

  .menu-container a {
    width: 80%;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}
