.navbar {
  position: relative;
  height: 99px;
  background-color: #333; /* Fallback background color */
  background-image: url("../../assets/frames/navbar-pattern.webp");
  background-repeat: repeat-x;
  // box-shadow: rgba(0, 0, 0, 0.85) 0px 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.95) 0px 30px 30px;
  display: flex;
  align-items: end;
  justify-content: space-between;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 378px;
    height: 99px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::before {
    left: 0;
    background-image: url("../../assets/frames/navbar-corner-left.webp");
  }

  &::after {
    right: 0;
    background-image: url("../../assets/frames/navbar-corner-right.webp");
  }

  .navbar-center {
    position: absolute;
    top: 0;
    left: 51.5%;
    transform: translateX(-50%);
    width: calc(100% - 756px); /* Subtract the width of the corner images */
    height: 99px;
    background-image: url("../../assets/frames/navbar-center.webp");
    background-repeat: no-repeat;
    background-position: center;
  }

  & .class-info-panel-toggle {
    z-index: 1;
    background-color: #333;
    border: none;
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 10px;
    &:hover {
      background-color: #555;
    }
  }

  .navbar-center-button-container {
  }

  .navbar-right-button-container {
    align-self: center;

    flex-wrap: wrap;
    z-index: 1;
  }

  .navbar-left-button-container {
    position: relative;

    align-self: center;
    flex-wrap: wrap;
    z-index: 1;
  }
}

/* Build Profiles button */
.build-profiles-button {
  z-index: 1;
  outline: none;
  border: none;
  width: 110px;
  height: 42px;
  color: #e0ceb4;
  font-size: 0.8rem;
  background: transparent;
  text-transform: uppercase;
  filter: drop-shadow(rgba(0, 0, 0, 0.8) 0px 0px 5px);
}

/* Class info button */
.class-info-button {
  z-index: 1;
  outline: none;
  border: none;
  width: 150px;
  height: 42px;
  color: #e0ceb4;
  font-size: 0.8rem;
  background: transparent;
  text-transform: uppercase;
  filter: drop-shadow(rgba(0, 0, 0, 0.8) 0px 0px 5px);
}

.navbar-mobile-menu {
  display: none; // Initially hidden
  position: fixed;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  z-index: 2;
  cursor: pointer;
  color: #e0ceb4;

  svg {
    width: 2rem;
    height: 2rem;
  }
}

/*========================== RESPONSIVE DESIGNS*/
@media (min-width: 1656px) {
  .navbar-left-button-container {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    justify-content: flex-start;
    gap: 10px;
  }

  .navbar-center-button-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .navbar-right-button-container {
    margin-right: 20px;
    margin-left: auto;
    margin-top: 20px;
    justify-content: flex-end;
    gap: 10px;
  }
}

@media (max-width: 1655px) {
  .navbar {
    justify-content: space-between;
  }

  .navbar-left-button-container {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    position: relative;
    justify-self: flex-start;
  }

  .navbar-center-button-container {
    position: relative;
    justify-self: flex-start;
  }

  .navbar-right-button-container {
    margin-right: 20px;
    margin-top: 20px;
    gap: 5px;
  }
}

@media (max-width: 1170px) {
  .navbar {
    justify-content: center;
    flex-wrap: wrap;
    height: 0px;
    margin-top: 0;
    background-image: none;
  }

  .navbar::before {
    display: none;
  }

  .navbar::after {
    display: none;
  }

  .navbar-center {
    display: none;
  }

  .navbar-left-button-container {
    margin-right: 3px;
    margin-left: 0px;
    margin-top: 3px;
  }

  .navbar-center-button-container {
    position: fixed;
    bottom: 29px;
  }

  .navbar-right-button-container {
    margin-left: 0;
    justify-content: center;
    gap: 3px;
    margin-right: 0px;
    margin-top: 3px;
  }
}

@media screen and (min-width: 769px) {
  .navbar-left-button-container {
    display: flex;
  }

  .navbar-right-button-container {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .navbar-left-button-container,
  .navbar-right-button-container {
    width: 50%;
    display: none; // Initially hidden
    flex-direction: column; // Stack buttons vertically
    margin-top: 0;
    gap: 0px;
  }

  .navbar-right-button-container {
    margin-right: 1px;
  }

  .navbar-mobile-menu {
    display: block; // Show the menu button
  }

  .navbar-left-button-container.show,
  .navbar-right-button-container.show {
    display: flex; // Now visible
  }
}
