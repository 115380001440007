.particles-wrapper {
  // position: absolute;
  overflow: hidden;
  box-sizing: border-box;
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
  width: 100%;
  height: 100%;
  pointer-events: none;

  & canvas {
    // mix-blend-mode: multiply;
    mix-blend-mode: overlay;
  }
}
