.skill-tooltip {
  position: absolute;
  padding: 20px;
  z-index: 1000;
  pointer-events: none;
  max-width: 450px;
  min-width: 200px;
  filter: drop-shadow(rgba(0, 0, 0, 1) 0px 2px 8px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  animation: fadeIn 0.3s ease;

  &::before,
  ::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
  }

  &::before {
    z-index: 0;
    background-image: url("../../assets/frames/gothic-frame-left-top.webp"),
      url("../../assets/frames/gothic-frame-right-top.webp"),
      url("../../assets/frames/gothic-frame-left-bottom.webp"),
      url("../../assets/frames/gothic-frame-right-bottom.webp"),
      url("../../assets/frames/gothic-frame-left-center.webp"),
      url("../../assets/frames/gothic-frame-right-center.webp"),
      url("../../assets/frames/gothic-frame-top-center.webp"),
      url("../../assets/frames/gothic-frame-bottom-center.webp");

    background-repeat: no-repeat;
    // Set the background-size based on the corner image dimensions
    background-size: 86px 86px, 86px 86px, 86px 86px, 86px 86px,
      8px calc(100% - 86px * 2), 8px calc(100% - 86px * 2),
      calc(100% - 86px * 2) 8px, calc(100% - 86px * 2) 8px;

    background-position: left top, right top, left bottom, right bottom,
      6px calc(50% + 0px), calc(100% - 6px) calc(50% - 0px), calc(50% + 0px) 6px,
      calc(50% - 0px) calc(100% - 6px);
  }

  &.visible {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltip-container {
  position: relative;
  z-index: 0;
  padding: 20px;
  width: 100%;
  left: 5px;

  &::before,
  ::after {
    content: "";
    position: absolute;
    top: -7px;
    left: -11px;
    right: -1px;
    bottom: -7px;
    z-index: -1;
  }

  &::before {
    background: linear-gradient(to top, #171412ef, #171412ef),
      url("../../assets/limestone-texture-pattern.webp") repeat top center;
    background-repeat: repeat;
    filter: sepia(50%) saturate(40%) contrast(118%) brightness(135%);
    // -webkit-filter: sepia(11%) saturate(80%) contrast(115%);
    // -moz-filter: sepia(11%) saturate(80%) contrast(115%);

    box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 5px 0px inset;
  }

  box-shadow: rgba(0, 0, 0, 0.9) 0px 3px 10px;
}

.inner-frame-container {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translate(-25px, -20px);
  filter: drop-shadow(rgba(0, 0, 0, 1) 0px 0px 2px);
  opacity: 0.6;
  // border: 2px solid #4c483c;
  outline: 1px solid #4c483c;
  box-sizing: border-box;
  // mix-blend-mode: screen;

  &::before,
  ::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 1;
  }

  &::before {
    z-index: 0;
    background-image: url("../../assets/frames/gothic-frame-light-left-top.webp"),
      url("../../assets/frames/gothic-frame-light-right-top.webp"),
      url("../../assets/frames/gothic-frame-light-left-bottom.webp"),
      url("../../assets/frames/gothic-frame-light-right-bottom.webp");
    // url("../../assets/gothic-frame-left-center.webp"),
    // url("../../assets/gothic-frame-right-center.webp"),
    // url("../../assets/gothic-frame-top-center.webp"),
    // url("../../assets/gothic-frame-bottom-center.webp");

    background-repeat: no-repeat;
    // Set the background-size based on the corner image dimensions
    background-size: 46px 46px, 46px 46px, 46px 46px, 46px 46px;

    background-position: left top, right top, left bottom, right bottom,
      6px calc(50% + 0px), calc(100% - 6px) calc(50% - 0px), calc(50% + 0px) 6px,
      calc(50% - 0px) calc(100% - 6px);
  }
}

.title-container {
  color: #e6e4d6;
  text-shadow: 1px 1px 3px #000000, -1px -1px 3px #380505;

  & h1 {
    margin-bottom: 0.1rem;
  }
}

.separator {
  margin-top: -10px;
}
.separator img {
  width: 100%;
  filter: drop-shadow(1px 0px 1px #000000);
}

.spell-rank-container {
  font-family: "OldFenris", sans-serif !important;
  text-align: center !important;
  line-height: 2rem !important;
  word-spacing: 4px;
  text-transform: uppercase;
  color: #f3e9d9 !important;
  font-size: 1.2rem;
  max-height: 32px;
  background-image: url("../../assets/frames/tooltip-banner.webp");
  background-position: center;
  background-size: cover;
  filter: drop-shadow(rgba(0, 0, 0, 0.7) 0px 0px 2px);
}

.points-not-allocated {
  color: #e60000;
  text-align: right;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.description {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Roboto", sans-serif;
  color: #b4aa9c;
  filter: drop-shadow(1px 0px 1px #000000);

  & * {
    font-family: "Roboto", sans-serif;
    word-spacing: -2px;
  }

  & mark,
  .description-value {
    color: #e7a94c;
    background-color: transparent;
  }
}

.description-mana-cost {
  color: #b79e7a;
  line-height: 2rem;

  & .description-value {
    color: #eaeb6d;
  }
}

.description-luck-hit-chance {
  color: #b79e7a;
  line-height: 2rem;
  // margin-bottom: 0.5rem;

  & .description-value {
    color: #eaeb6d;
  }
}

.description-cooldown {
  color: #b79e7a;
  line-height: 2rem;
  // margin-bottom: 0.5rem;

  & .description-value {
    color: #eaeb6d;
  }
}

.description-value {
  font-family: "Roboto", sans-serif;
  color: #d9a962;
}

.description-extra-value {
  font-family: "Roboto", sans-serif;
  color: #d9a962;
}

.mana-cost-value {
  font-family: "Roboto", sans-serif;
  color: #eaeb6d;
}

.enchantment-title {
  font-family: "OldFenris", sans-serif !important;
  text-align: center !important;
  line-height: 2rem !important;
  word-spacing: 4px;
  text-transform: uppercase;
  color: #f3e9d9 !important;
  font-size: 1rem !important;
  background-image: url("../../assets/frames/tooltip-banner.webp");
  background-position: center;
  background-size: cover;
  margin-top: 0.5rem;
}

.enchantment-effect {
}

.ultimate-description {
  text-align: left;
  font-family: "Roboto", sans-serif;
  color: #c2702e;
  margin-top: 0.3rem;
  margin-bottom: 0.1rem;
}

.image-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  display: inline-block;
}

.tooltip-frame-image {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Make sure the frame does not interfere with user interactions */
}

.tooltip-spell-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 0.8;
  filter: drop-shadow(rgba(0, 0, 0, 0.7) 0px 0px 2px);
}

.tooltip-spell-image-clip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(25% 0, 100% 25%, 75% 100%, 0 75%);
  z-index: 2;
  pointer-events: none;
}

.tags-container {
  overflow: hidden;
  & ul {
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
    padding-left: 0;
  }

  & li {
    text-align: center;
    vertical-align: middle;
    background-color: rgba(120, 120, 120, 0.2);
    border: 2px solid rgba(120, 120, 120, 0.2);
    padding: 3px;
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    color: #ccb490;
    height: 100%;
    filter: drop-shadow(rgba(0, 0, 0, 1) 0px 0px 3px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 20px;
  }

  & .skill-category {
    background-color: rgba(0, 131, 0, 0.2);
  }

  /* DMG TYPES */
  & .fire,
  .burn,
  .pyromancy {
    background-color: rgba(255, 102, 0, 0.2);
  }

  & .cold,
  .freeze,
  .chill,
  .frost,
  .frozen {
    background-color: rgba(87, 174, 255, 0.2);
  }

  & .lightning,
  .shock {
    background-color: rgba(0, 47, 255, 0.2);
  }

  & .poison {
    background-color: rgba(73, 128, 11, 0.2);
  }
  & .shadow {
    background-color: rgba(91, 35, 99, 0.2);
  }

  /* DMG EFFECTS */
  & .vulnerable {
    background-color: rgba(183, 0, 255, 0.2);
  }

  & .unstoppable {
    background-color: rgba(0, 162, 255, 0.2);
  }

  & .bleed {
    background-color: rgba(194, 0, 0, 0.2);
  }

  /* CLASS SPECIFIC TAGS */
  & .blood {
    background-color: rgba(194, 0, 0, 0.2);
  }
  & .bone {
    background-color: rgba(211, 166, 99, 0.2);
  }
  & .darkness {
    background-color: rgba(32, 32, 32, 0.2);
  }
  & .earth {
    background-color: rgba(214, 139, 78, 0.2);
  }
  & .storm {
    background-color: rgba(102, 121, 206, 0.2);
  }
  & .werewolf {
    background-color: rgba(84, 46, 155, 0.2);
  }
  & .werebear {
    background-color: rgba(235, 127, 64, 0.2);
  }
}

.damagetype-info-container {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 0px;
  filter: drop-shadow(1px 0px 1px #000000);

  & .separator-right {
    width: 230px;
  }

  & .icon-and-info {
    display: flex;
    justify-content: end;
    margin-top: -5px;
  }

  & span {
    font-family: "Roboto", sans-serif;
    color: #b4aa9c;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  & img {
    margin-top: -2px;
    margin-right: 5px;
    width: 24px;
    height: 24px;
  }
}

.point-allocating-help {
  position: relative;
  height: 55px;
  width: 103%;
  margin-left: -6px;
  margin-top: -2px;
  margin-bottom: -6px;
  padding: 15px 15px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0px;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-image: url("../../assets/backgrounds/stone-texture-light.webp");
    box-shadow: rgba(0, 0, 0, 0.7) 0px 1px 3px inset;
  }

  &::after {
    content: "";
    position: absolute;
    top: 12px;
    left: 2px;
    bottom: 1px;
    right: 2px;
    z-index: -1;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  & img {
    width: 27px;
    height: 28px;
    margin-top: 8px;
    margin-right: 10px;
  }

  & span {
    padding-top: 10px;
    font-family: "Roboto", sans-serif;
    color: #b4aa9c;
    font-size: 1.2rem;
  }
}

/*========================== RESPONSIVE DESIGNS*/
@media (max-width: 765px) {
  .skill-tooltip {
    width: 100%;
    max-width: 100%;
  }
  .tooltip-container {
    font-size: 0.8rem;
  }

  .separator {
    display: none;
  }

  .description {
    font-size: 0.8rem;
    line-height: 1.2;
  }

  .title-container {
    font-size: 0.8rem;
  }

  .spell-rank-container {
    font-size: 0.8rem;
  }

  .point-allocating-help {
    display: none;
  }

  .damagetype-info-container .separator-right {
    width: 150px;
  }

  .damagetype-info-container span {
    font-size: 0.7rem;
    margin: 0;
  }

  .points-not-allocated {
    font-size: 0.7rem;
    margin: 0;
  }
}
