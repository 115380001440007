.skill-tree {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 20px 0px inset;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../assets//backgrounds//stone-texture-light-coarse.webp");
  background-repeat: repeat;
  overflow: hidden;

  & svg {
    height: 100.5%;
    margin-bottom: -5px;
  }

  /*Left screen edge frame*/
  &::before {
    content: "";
    position: fixed;
    z-index: 1;
    width: 8px;
    height: 100%;
    left: 0;
    top: 99px;
    background-image: url("../../assets/frames/screen-edge-frame-left.webp");
    background-repeat: repeat-y;
    box-shadow: rgba(0, 0, 0, 0.75) 3px 0px 5px;
  }

  /*Right screen edge frame*/
  &::after {
    content: "";
    position: fixed;
    z-index: 1;
    width: 8px;
    height: 100%;
    right: 0;
    top: 99px;
    background-image: url("../../assets/frames/screen-edge-frame-right.webp");
    background-repeat: repeat-y;
    box-shadow: rgba(0, 0, 0, 0.75) -3px 0px 5px;
  }
}

.hover-frame {
  opacity: 0.8;
  filter: drop-shadow(0px 0px 3px #a80000);
}

.glow-effect {
  // opacity: 0.4;
}

.skill-node {
  box-shadow: rgba(150, 150, 193, 0.85) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.skill-node:hover {
  border: 5px solid #eb0000;
}
.skill-node-image {
  box-shadow: rgba(150, 150, 193, 0.85) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.skill-node-image:hover {
}
.spell-node-image {
  opacity: 0.8;
}

.active-node:hover {
  // cursor: pointer;
}

.allocated-node image {
}

.node-link {
  // fill: #fff;
}

.link:hover {
  stroke: #eb0000 !important;
}

.node-text {
  font-size: 0.7rem;
  fill: #fff;
}

.point-indicator {
  letter-spacing: -1px;
  translate: 25px 25px;
  fill: #fff;
  filter: drop-shadow(0px 0px 2px #000000);
}

.hover-point-indicator {
  letter-spacing: -1px;
  translate: 25px 25px;
  fill: #fff;
  filter: drop-shadow(0px 0px 2px #000000);
}

.nodeHub-counter {
  font-family: "OldFenris", sans-serif !important;
  font-size: 2rem;
  font-weight: 400;
  fill: #f50802;
  translate: 0px 8px;
  filter: drop-shadow(0px 0px 3px #000000);
}

/*========================== RESPONSIVE DESIGNS*/
@media (max-width: 1170px) {
  .skill-tree::before {
    display: none;
  }

  .skill-tree::after {
    display: none;
  }
}
