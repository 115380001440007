/*===================== GLOBAL VARIABLES =====================*/
// @font-face {
//   font-family: "OldFenris";
//   src: local("OldFenris"), url("/fonts/OldFenris.ttf") format("truetype");
// }
// @font-face {
//   font-family: "Diablo";
//   src: local("Diablo"), url("/fonts/DIABLO_H.TTF") format("truetype");
// }
// @font-face {
//   font-family: "TSGRomulus";
//   src: local("TSGRomulus"), url("/fonts/TSGRomulus-Bold.ttf") format("truetype");
// }
// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"), url("/fonts/Roboto-Regular.ttf") format("truetype");
// }

/*===================== CUSTOM DESIGN =====================*/
body {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("assets/leather-texture_800.webp");
  background-repeat: repeat;
  text-align: center;
  // overflow: hidden;
  // padding: 1em;

  // font-family: 'Cairo', sans-serif;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  // -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -webkit-transform: translateZ(0);
  // -webkit-backface-visibility: hidden;
}

* {
  box-sizing: border-box;
  font-family: "OldFenris", sans-serif;
  letter-spacing: 1px;
}

::selection {
  background: #6d1212; /* Your color here */
  // color: white;
}

/* For Firefox */
::-moz-selection {
  background: #6d1212; /* Your color here */
  color: white;
}

/* APP PRIMARY BUTTON */
.btn[type="primary"] {
  width: 100%;
  backdrop-filter: none;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.5);
  color: #e5e0c8;
  filter: drop-shadow(0px 0px 33px rgba(0, 0, 0, 0.66))
    drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.66));
  background-color: transparent;
  transition: color ease-in-out 350ms;
  // height: 93px;
  padding: 1.5rem 2.5rem;
  // font-size: 27px;
  // line-height: 24.3px;

  &::before,
  ::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    z-index: -1;
    transition: opacity ease-in-out 350ms, color ease-in-out 350ms;

    background-repeat: no-repeat;
    background-position: left center, right center, center center, 10px center,
      calc(100% - 10px) center, center center;
    background-size: auto 100%, auto 100%, auto 100%, auto calc(100% - 18px),
      auto calc(100% - 18px), 82% calc(100% - 18px);
  }

  &::before {
    opacity: 0;
    background-image: url(assets/buttons/d4-button-filigree-left.webp),
      url(assets/buttons/d4-button-filigree-right.webp),
      url(assets/buttons/d4-button-filigree-center.webp),
      url(assets/buttons/d4-button-primary-left-base.webp),
      url(assets/buttons/d4-button-primary-right-base.webp),
      url(assets/buttons/d4-button-primary-tile-base.webp);
  }

  &::after {
    opacity: 1;
    background-image: url(assets/buttons/d4-button-filigree-left.webp),
      url(assets/buttons/d4-button-filigree-right.webp),
      url(assets/buttons/d4-button-filigree-center.webp),
      url(assets/buttons/d4-button-primary-left-hover.webp),
      url(assets/buttons/d4-button-primary-right-hover.webp),
      url(assets/buttons/d4-button-primary-tile-hover.webp);
  }

  &:part {
    backdrop-filter: none;
  }

  &:not([disabled]):hover {
    color: #f6f8f0;
  }

  &:not([disabled]):hover::after {
    opacity: 1;
  }
  &:not([disabled]):hover::before {
    opacity: 0;
  }

  &:active,
  :focus {
    transform: translateY(3px);
  }
}

// .btn[type="primary"]::after {
//   background-image: url(assets/d4-button-filigree-left.webp),
//     url(assets/d4-button-filigree-right.webp),
//     url(assets/d4-button-filigree-center.webp),
//     url(assets/d4-button-primary-left-hover.webp),
//     url(assets/d4-button-primary-right-hover.webp),
//     url(assets/d4-button-primary-tile-hover.webp);
// }

// .btn[type="primary"]::before,
// .btn[type="primary"]::after {
//   background-repeat: no-repeat;
//   background-position: left center, right center, center center, 10px center,
//     calc(100% - 10px) center, center center;
//   background-size: auto 100%, auto 100%, auto 100%, auto calc(100% - 18px),
//     auto calc(100% - 18px), 82% calc(100% - 18px);
// }

.btn:not([disabled]):hover::after {
  opacity: 1;
}

.btn[disabled] {
  filter: saturate(0) contrast(0.8);
  cursor: default;
  backdrop-filter: none;
}

@media (min-width: 960px) {
  btn[type="primary"]::before,
  blz-button[type="primary"]::after {
    background-size: auto 100%, auto 100%, auto 100%, auto calc(100% - 20px),
      auto calc(100% - 20px), 82% calc(100% - 20px);
  }
}

.blz-button {
  font-weight: 400;
  // line-height: 0.9;
  text-transform: uppercase;
  text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.5);
  color: #e5e0c8;
  filter: drop-shadow(0px 0px 33px rgba(0, 0, 0, 0.66))
    drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.66));
  background-color: transparent;
  transition: color 350ms;
  // font-size: 22.5px;
  // line-height: 20.25px;
}
.blz-button::part(host-anchor) {
  backdrop-filter: none;
}
.blz-button[disabled] {
  filter: saturate(0) contrast(0.8);
  cursor: default;
  backdrop-filter: none;
}
@media (min-width: 960px) {
  .blz-button {
    font-size: 20px;
    line-height: 24.3px;
  }
}
.blz-button::before,
.blz-button::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  z-index: -1;
  transition: opacity 350ms, color 350ms;
}
.blz-button:active:focus {
  transform: translateY(3px);
}

.blz-button::after {
  opacity: 0;
}

.blz-button:not([disabled]):hover {
  color: #f6f8f0;
}

.blz-button:not([disabled]):hover::after {
  opacity: 1;
}

.blz-button[type="default"]::before,
.blz-button[type="default"]::after {
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-position: left center, right center, center center;
  background-size: auto 100%;
}

.blz-button[type="default"]::before {
  background-image: url(assets/buttons/d4-button-secondary-left-base.webp),
    url(assets/buttons/d4-button-secondary-right-base.webp),
    url(assets/buttons/d4-button-secondary-tile-base.webp);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  // .blz-button[type="default"]::before {
  //   background-image: url(assets/d4-button-secondary-left-base@2x.webp),
  //     url(assets/d4-button-secondary-right-base@2x.webp),
  //     url(assets/d4-button-secondary-tile-base@2x.webp);
  // }
}

.blz-button[type="default"]::after {
  background-image: url(assets/buttons/d4-button-secondary-left-hover.webp),
    url(assets/buttons/d4-button-secondary-right-hover.webp),
    url(assets/buttons/d4-button-secondary-tile-hover.webp);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  // .blz-button[type="default"]::after {
  //   background-image: url(assets/d4-button-secondary-left-hover@2x.webp),
  //     url(assets/d4-button-secondary-right-hover@2x.webp),
  //     url(assets/d4-button-secondary-tile-hover@2x.webp);
  // }
}

.blz-button[type="primary"] {
  padding: 1.8rem 1.6rem;
  font-size: 1.2rem;
}

@media (min-width: 1250px) {
  .blz-button[type="primary"] {
    font-size: 1.5rem;
  }
}

@media (max-width: 1630px) {
  .blz-button[type="primary"] {
    font-size: 1.2rem;
  }
}

@media (max-width: 1390px) {
  .blz-button[type="primary"] {
    font-size: 1rem;
  }
}

@media (max-width: 1210px) {
  .blz-button[type="primary"] {
    font-size: 0.8rem;
  }
}

@media (max-width: 960px) {
  .blz-button[type="primary"] {
    font-size: 0.8rem;
  }
}

@media (max-width: 762px) {
  .blz-button[type="primary"] {
    font-size: 0.8rem;
  }
}

.blz-button[type="primary"]::before,
.blz-button[type="primary"]::after {
  background-repeat: no-repeat;
  background-position: left center, right center, center center, 10px center,
    calc(100% - 10px) center, center center;
  background-size: auto 100%, auto 100%, auto 100%, auto calc(100% - 18px),
    auto calc(100% - 18px), 82% calc(100% - 18px);
}

@media (min-width: 960px) {
  .blz-button[type="primary"]::before,
  .blz-button[type="primary"]::after {
    background-size: auto 100%, auto 100%, auto 100%, auto calc(100% - 20px),
      auto calc(100% - 20px), 82% calc(100% - 20px);
  }
}

.blz-button[type="primary"]::before {
  background-image: url(assets/buttons/d4-button-filigree-left.webp),
    url(assets/buttons/d4-button-filigree-right.webp),
    url(assets/buttons/d4-button-filigree-center.webp),
    url(assets/buttons/d4-button-primary-left-base.webp),
    url(assets/buttons/d4-button-primary-right-base.webp),
    url(assets/buttons/d4-button-primary-tile-base.webp);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  // .blz-button[type="primary"]::before {
  //   background-image: url(assets/d4-button-filigree-left@2x.webp),
  //     url(assets/d4-button-filigree-right@2x.webp),
  //     url(assets/d4-button-filigree-center@2x.webp),
  //     url(assets/d4-button-primary-left-base@2x.webp),
  //     url(assets/d4-button-primary-right-base@2x.webp),
  //     url(assets/d4-button-primary-tile-base@2x.webp);
  // }
}

.blz-button[type="primary"]::after {
  background-image: url(assets/buttons/d4-button-filigree-left.webp),
    url(assets/buttons/d4-button-filigree-right.webp),
    url(assets/buttons/d4-button-filigree-center.webp),
    url(assets/buttons/d4-button-primary-left-hover.webp),
    url(assets/buttons/d4-button-primary-right-hover.webp),
    url(assets/buttons/d4-button-primary-tile-hover.webp);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  // .blz-button[type="primary"]::after {
  //   background-image: url(assets/d4-button-filigree-left@2x.webp),
  //     url(assets/d4-button-filigree-right@2x.webp),
  //     url(assets/d4-button-filigree-center@2x.webp),
  //     url(assets/d4-button-primary-left-hover@2x.webp),
  //     url(assets/d4-button-primary-right-hover@2x.webp),
  //     url(assets/d4-button-primary-tile-hover@2x.webp);
  // }
}

.rd3t-node text {
  fill: #fff;
  stroke-width: 0;
}

.rd3t-leaf-node text {
  fill: #fff;
  stroke-width: 0;
}

/* ============================== DIABLO 4 BUTTON */
.d4-button {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: url("./assets/buttons/d4-button-left.webp"),
      url("./assets/buttons/d4-button-right.webp"),
      url("./assets/buttons/d4-button-center.webp");
    background-repeat: no-repeat;
    background-size: 26px 42px, 26px 42px, calc(100% - 20px * 2) 40px;
    background-position: left, right, 20px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    background-image: url("./assets/buttons/d4-button-left-hover.webp"),
      url("./assets/buttons/d4-button-right-hover.webp"),
      url("./assets/buttons/d4-button-center-hover.webp");
    background-repeat: no-repeat;
    background-size: 26px 42px, 26px 42px, calc(100% - 20px * 2) 40px;
    background-position: left, right, 20px;
    transition: opacity 150ms ease-out;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover::after {
    opacity: 1;
  }

  &:active {
    font-size: 0.75rem;
  }

  &:active::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: url("./assets/buttons/d4-button-left-active.webp"),
      url("./assets/buttons/d4-button-right-active.webp"),
      url("./assets/buttons/d4-button-center-active.webp");
    background-repeat: no-repeat;
    background-size: 26px 42px, 26px 42px, calc(100% - 15px * 2) 40px;
    background-position: left, right, 18px;
    transition: opacity 150ms ease-out;
  }
}

@media screen and (max-width: 768px) {
  .d4-button {
    width: 100%;
    height: 42px;
    margin: 1px 0;
  }
}

.d4-button-mini {
  z-index: 100;
  background: url("./assets/buttons/gothic-red-icon-button-2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  outline: none;
  border: none;
  width: 42px;
  height: 42px;
  color: #e0ceb4;
  font-size: 1.5rem;
  filter: drop-shadow(rgba(0, 0, 0, 0.8) 0px 0px 5px);

  &:hover {
    background: url("./assets/buttons/gothic-red-icon-button-2-hover.webp");
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: opacity 350ms ease-in-out;
  }

  &:active {
    background: url("./assets/buttons/gothic-red-icon-button-2-active.webp");
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    font-size: 1.2rem;
    transition: opacity 350ms ease-in-out;
  }

  &:active svg {
    transform: scale(0.8);
  }
}

/* ============================== PANEL CLOSE BUTTON */
.panel-close-button {
  z-index: 2;
  background: transparent;
  border: none;
  outline: none;
  width: 55px;
  height: 54px;
  background-image: url("./assets/buttons/close-button.webp");
  background-repeat: no-repeat;
  background-size: 55px 54px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    background-image: url("./assets/buttons/close-button-hover.webp");
    background-repeat: no-repeat;
    background-size: 55px 54px;
    transition: opacity 150ms ease-out;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover::after {
    opacity: 1;
  }

  &:active {
    background-image: url("./assets/buttons/close-button-active.webp");
  }

  &:active::after {
    opacity: 0;
  }
}

/* ============================== PANEL BORDER AND INNER SHADOW */
.inner-panel {
  box-shadow: inset 0px 0px 20px 10px rgba(0, 0, 0, 0.8);
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.3) 90%,
    rgba(0, 0, 0, 0.7) 100%
  );

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: url("./assets/frames/border-panel-top.webp"),
      url("./assets/frames/border-panel-bottom.webp"),
      url("./assets/frames/border-panel-side.webp"),
      url("./assets/frames/border-panel-side.webp");
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 100px 2px, 100px 2px, 2px 100px, 2px 100px;
    background-position: top, bottom, left, right;
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   z-index: -2;
  //   background-image: url("./assets/backgrounds/panel-inner-shadow-corner-top-left.webp"),
  //     url("./assets/backgrounds/panel-inner-shadow-corner-bottom-left.webp"),
  //     url("./assets/backgrounds/panel-inner-shadow-corner-top-right.webp"),
  //     url("./assets/backgrounds/panel-inner-shadow-corner-bottom-right.webp");
  //   background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  //   background-size: 96px 96px, 96px 96px, 96px 96px, 96px 96px;
  //   background-position: 2px 2px, 2px calc(100% - 2px), calc(100% - 2px) 2px,
  //     calc(100% - 2px) calc(100% - 2px);
  // }
}

/* ============================== SCROLLBAR */
ul::-webkit-scrollbar {
  width: 15px;
}

ul::-webkit-scrollbar-track {
  background-image: url("./assets/frames/scroll-middle-small.webp");
  background-size: 15px 304px;
  background-repeat: repeat-y;
}

ul::-webkit-scrollbar-thumb {
  background-image: url("./assets/frames/scroll-middle-thumb-small.webp");
  background-size: cover;
  // box-shadow: rgba(0, 0, 0, 0.9) 0px 2px 5px 1px;
}

/* scrollbar thumb color on hover */
ul::-webkit-scrollbar-thumb:hover {
  background-image: url("./assets/frames/scroll-middle-thumb-small-hover.webp");
  background-size: cover;
}

ul::-webkit-scrollbar-button:start {
  background-image: url("./assets/frames/scroll-up-small.webp");
}

ul::-webkit-scrollbar-button:end {
  background-image: url("./assets/frames/scroll-down-small.webp");
}
