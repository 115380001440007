.point-panel {
  width: 250px;
  height: 138px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/backgrounds/stone-texture-dark-coarse-small.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
  color: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.95) 0px 0px 5px);

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: url("../../assets/point-panel-frame.webp");
  }

  & .point-info {
    display: flex;
    align-items: center;
    justify-content: center;

    & .remaining-points {
      color: #e9bd55;
      margin: 0 1.2rem;
    }

    & h3 {
      margin: 0;
      font-size: 1.1rem;
    }

    & span {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }

    & h3,
    span,
    p {
      background-image: linear-gradient(0deg, #e0ceb4, #afa698);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 100%;
      filter: drop-shadow(rgba(100, 58, 58, 0.5) 1px 1px 1px);
    }
  }

  & .points-left {
    flex-direction: column;
  }
}

.point-panel .small-screen {
  display: none;
}

/*========================== RESPONSIVE DESIGNS*/
@media (min-width: 1031px) {
  .point-panel {
    position: absolute;
    left: 30px;
    top: 120px;
  }
}

@media (max-width: 1030px) {
  .point-panel {
    background: none;
    position: fixed;
    bottom: 90px;
    left: 0%;
    width: 70px;
    height: 40px;
  }

  .point-panel::before {
    display: none;
  }

  .point-panel .point-info {
    display: none;
  }

  .point-panel .small-screen {
    display: block;
  }

  .small-screen span {
    font-size: 1.3rem;
    right: 0;
  }
}
