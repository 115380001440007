.class-select-dropdown {
  position: relative;
  display: inline-block;
  padding-bottom: 7px;
  user-select: none;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    bottom: 6px;
    left: 8px;
    right: 8px;
    height: 5px;
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.86);
    z-index: 1;
  }

  .class-select-dropdown-toggle {
    padding: 15px 25px;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.8);
    color: #e5e0c8;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.16))
      drop-shadow(0px -4px 5px rgba(0, 0, 0, 0.16));
    background-color: transparent;
    transition: all 300ms ease;
    font-size: 1.2rem;
    cursor: pointer;
    width: 250px;

    &::before,
    &::after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      z-index: -1;
      transition: opacity 300ms ease, color 300ms ease;
    }

    &::before {
      opacity: 1;
      background-repeat: no-repeat;
      background-position: 10px center, calc(100% - 10px), center center;
      background-size: auto calc(100% - 10px), auto calc(100% - 10px),
        90% calc(100% - 10px);
      background-image: url("../../assets/frames/gothic-grey-tab-frame-left.webp"),
        url("../../assets/frames/gothic-grey-tab-frame-right.webp"),
        url("../../assets/frames/gothic-grey-tab-frame-center.webp");
    }

    &::after {
      opacity: 0;
      background-repeat: no-repeat;
      background-position: 10px center, calc(100% - 10px), center center;
      background-size: auto calc(100% - 10px), auto calc(100% - 10px),
        90% calc(100% - 10px);
      background-image: url("../../assets/frames/gothic-red-tab-frame-left.webp"),
        url("../../assets/frames/gothic-red-tab-frame-right.webp"),
        url("../../assets/frames/gothic-red-tab-frame-center.webp");
    }

    &:hover {
      color: #f6f8f0;
      text-shadow: 2px 1px 5px rgba(255, 255, 255, 0.8);

      &::after {
        opacity: 1;
      }
    }

    &:active,
    :focus {
      color: #f6f8f0;

      &::after {
        opacity: 1;
      }
    }

    &:hover .dropdown-arrow {
      fill: #f6f8f0;
      filter: drop-shadow(2px 1px 5px rgba(255, 255, 255, 0.8));
    }

    & .dropdown-arrow {
      display: inline-block;
      width: 16px; // Set the width of your icon
      height: 16px; // Set the height of your icon
      margin-left: 8px;
      margin-top: -4px;
      background-image: url("../../assets/icons/drop-down-arrow.webp");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      vertical-align: middle;
      transition: filter 300ms ease;
    }
  }

  .class-select-dropdown-menu {
    position: absolute;
    z-index: 1;
    padding: 30px 30px;
    margin: 0;
    font-size: 1rem;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    filter: drop-shadow(rgba(0, 0, 0, 1) 0px 2px 8px);

    &::before {
      position: absolute;
      content: "";
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      z-index: -1;
      background-image: linear-gradient(to top, #17141254, #17141246),
        url("../../assets/backgrounds/stone-texture-light.webp");
      box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 5px 7px inset;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-image: url("../../assets/frames/gothic-frame-left-top.webp"),
        url("../../assets/frames/gothic-frame-right-top.webp"),
        url("../../assets/frames/gothic-frame-left-bottom.webp"),
        url("../../assets/frames/gothic-frame-right-bottom.webp"),
        url("../../assets/frames/gothic-frame-left-center.webp"),
        url("../../assets/frames/gothic-frame-right-center.webp"),
        url("../../assets/frames/gothic-frame-top-center.webp"),
        url("../../assets/frames/gothic-frame-bottom-center.webp");
      background-repeat: no-repeat;
      // Set the background-size based on the corner image dimensions
      background-size: 86px 86px, 86px 86px, 86px 86px, 86px 86px,
        8px calc(100% - 86px * 2), 8px calc(100% - 86px * 2),
        calc(100% - 85px * 2) 8px, calc(100% - 85px * 2) 8px;

      background-position: left top, right top, left bottom, right bottom,
        6px calc(50% + 0px), calc(100% - 5px) calc(50% - 0px),
        calc(50% + 0px) 6px, calc(50% - 0px) calc(100% - 6px);
    }

    /* Custom background images for each item */
    .class-select-dropdown-item {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 1rem 1rem;
      font-weight: 400;
      color: #e5e0c8;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      line-height: 1rem;
      text-transform: uppercase;
      filter: drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 3px);

      &::before,
      &::after {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: -1;
        transition: opacity 300ms ease, color 300ms ease;
      }

      &::before {
        width: 100%;
        opacity: 1;
        background-repeat: no-repeat;
        background-position: 10px center, calc(100% - 10px), center center;
        background-size: auto calc(100% - 10px), auto calc(100% - 10px),
          90% calc(100% - 10px);
        background-image: url("../../assets/buttons/gothic-grey-button-left.webp"),
          url("../../assets/buttons/gothic-grey-button-right.webp"),
          url("../../assets/buttons/gothic-grey-button-center.webp");
      }

      &::after {
        width: 100%;
        opacity: 0;
        background-repeat: no-repeat;
        background-position: 10px center, calc(100% - 10px), center center;
        background-size: auto calc(100% - 10px), auto calc(100% - 10px),
          92% calc(100% - 10px);
        background-image: url("../../assets/buttons/gothic-red-button-left.webp"),
          url("../../assets/buttons/gothic-red-button-right.webp"),
          url("../../assets/buttons/gothic-red-button-center.webp");
      }

      &:hover,
      &:focus {
        color: #fff;
        background-color: transparent;
        text-shadow: 2px 1px 5px rgba(255, 255, 255, 0.8);

        &::after {
          opacity: 1;
          //   mix-blend-mode: multiply;
        }
      }

      &.active,
      &:active {
        color: #fff;
        background-color: transparent;

        &::before,
        &::after {
          opacity: 1;
          //   mix-blend-mode: multiply;
        }
      }
    }
  }
}
// Css Transition Animation
.dropdown-menu-animation-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.dropdown-menu-animation-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;
}

.dropdown-menu-animation-exit {
  opacity: 1;
  transform: translateY(0px);
}

.dropdown-menu-animation-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;
}

/*========================== RESPONSIVE DESIGNS*/
@media (min-width: 1171px) {
  .class-select-dropdown-menu {
    top: 80px;
  }
}

@media (max-width: 1170px) {
  .class-select-dropdown-menu {
    bottom: 60px;
  }
  .dropdown-menu-animation-enter {
    opacity: 0;
    transform: translateY(+20px);
  }

  .dropdown-menu-animation-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;
  }

  .dropdown-menu-animation-exit {
    opacity: 1;
    transform: translateY(0px);
  }

  .dropdown-menu-animation-exit-active {
    opacity: 0;
    transform: translateY(+20px);
    transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;
  }
}
