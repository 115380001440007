.search-container {
  height: 42px;
  // width: 250px;
  z-index: 100;
  filter: drop-shadow(rgba(0, 0, 0, 0.8) 0px 0px 5px);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: url("../../assets/buttons/d4-button-left.webp"),
      url("../../assets/buttons/d4-button-right.webp"),
      url("../../assets/buttons/search-box-center.webp");
    background-repeat: no-repeat;
    background-size: 26px 42px, 26px 42px, calc(100% - 26px * 2) 42px;
    background-position: left, right, 26px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    background-image: url("../../assets/buttons/search-box-left-active.webp"),
      url("../../assets/buttons/search-box-right-active.webp"),
      url("../../assets/buttons/search-box-center-active.webp");
    background-repeat: no-repeat;
    background-size: 26px 42px, 26px 42px, calc(100% - 26px * 2) 42px;
    background-position: left, right, 26px;
    transition: opacity 350ms ease-in-out;
  }

  &:focus-within::after {
    opacity: 1;
  }

  & .clear-search {
    position: absolute;
    right: 8px;
    top: -3px;
    border: none;
    background: none;
    color: #e0ceb4;
    font-size: 1.8rem;

    &:hover {
      cursor: pointer;
      color: #fdd8a5;
      text-shadow: rgba(255, 255, 255, 0.8) 0px 0px 3px;
    }
  }
}

.search-input {
  // width: 210px;
  padding: 8px 10px;
  // padding-left: 0px;
  // padding-right: 15px;
  // margin-left: -15px;
  border: none;
  font-size: 14px;
  outline: none;
  background: transparent;
  color: #e0ceb4;
  font-size: 1.2rem;

  &::placeholder {
    color: #5c5348;
    text-shadow: rgba(0, 0, 0, 0.95) 0px 0px 3px;
  }
}

/*========================== RESPONSIVE DESIGNS*/
@media (max-width: 680px) {
  .search-container {
    right: 20px;
    top: 110px;
  }
}

@media screen and (max-width: 768px) {
  .search-container {
    display: none;
  }
}
