.index-footer {
  position: fixed;
  bottom: 0;
  height: 40px;
  width: 100%;
  background: #111111;
  background-image: url("../../assets/frames/navbar-pattern-subpage.webp");
  background-size: 250px 40px;
  background-repeat: repeat-x;
  box-shadow: none;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & span {
    font-size: 10px;
    font-family: "Roboto", sans-serif;
    word-spacing: 0px;
    color: #b4aa9c;
    padding: 0 5px;
    display: inline-block;
  }

  & .app-copyright-info {
    flex-basis: 100%;
    flex-shrink: 1;
    margin-top: 5px;
  }

  & a {
    color: #d9a962;
    text-decoration: none;
    // border-bottom: 1px solid #b4aa9c;
    margin: 0 5px;
  }

  & .official-d4-link {
    display: none;
  }
}
