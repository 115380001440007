.class-container {
  width: 100%;
  height: 100dvh;
}

.content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.class-menu {
  display: flex;
  position: relative;
  z-index: 1;
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 1.5rem;

  justify-content: center;
  align-items: flex-end;

  & * {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
  }
}

.class-menu li {
  width: 15%;
  max-width: 20rem;
  margin-bottom: 0.2rem;
}

.class-menu li:nth-child(1) {
  transform: rotate(-50deg) translateY(-100px) rotate(50deg);
}

.class-menu li:nth-child(2) {
  transform: rotate(-20deg) translateY(-180px) rotate(20deg);
}

.class-menu li:nth-child(3) {
  transform: translateY(-200px);
}

.class-menu li:nth-child(4) {
  transform: rotate(20deg) translateY(-180px) rotate(-20deg);
}

.class-menu li:nth-child(5) {
  transform: rotate(50deg) translateY(-100px) rotate(-50deg);
}

.class-menu a {
  display: inline-block;
  text-decoration: none;
  width: 100%;
}

.menu-title {
  font-size: 1.3rem;
  color: #d2c8ae;
}

.class-menu-item {
  font-size: 1.5rem;
  padding-top: 1rem;
  color: #eee3c6;
  height: 100px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: url("../../assets/frames/character-select-frame.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top;
  }
}

.class-button-help {
  display: none;

  & span {
    font-size: 1rem;
  }
}

.video-background {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

/*========================== RESPONSIVE DESIGNS*/
@media (min-width: 1031px) {
  .class-menu {
    align-items: center;
    // top: 100px;
    transform: translateY(-200px);
    overflow: hidden;
  }

  .class-menu-item {
    // transition: filter 350ms ease;

    &:hover {
      filter: drop-shadow(rgba(184, 0, 0, 0.7) 0px 0px 8px);
      color: #fff;
    }

    &:active {
      transform: translateY(5px);
    }
  }

  .class-button-help {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 1.5rem;
  }

  .class-select-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mouse-icon {
    width: 20px;
    margin-right: 5px;
  }
}

@media (max-width: 1740px) {
  .class-menu li {
    width: 15%;
    max-width: 17rem;
  }

  .class-menu-item {
  }
  .class-menu li:nth-child(1) {
    transform: rotate(-10deg) translateY(-100px) rotate(10deg);
  }

  .class-menu li:nth-child(2) {
    transform: rotate(-10deg) translateY(-200px) rotate(10deg);
  }

  .class-menu li:nth-child(3) {
    transform: translateY(-200px);
  }

  .class-menu li:nth-child(4) {
    transform: rotate(10deg) translateY(-200px) rotate(-10deg);
  }

  .class-menu li:nth-child(5) {
    transform: rotate(10deg) translateY(-100px) rotate(-10deg);
  }
}

@media (max-width: 1030px) {
  .class-container {
    margin-top: -1rem;
  }
}

@media (max-width: 960px) {
  .class-menu li {
    width: 100%;
    margin: 0 auto;
    // margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }

  .class-menu-item {
    max-width: 100%;
  }
}

@media (min-aspect-ratio: 16/9) {
  video {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  video {
    width: auto;
    height: 100%;
  }
}

// Show an image on mobile devices
@media (max-width: 768px) {
  video {
    display: none;
  }

  .class-container {
    width: 100%;
    background-image: url("../../assets/backgrounds/lilith-silhouette-bg-small.webp"); /* Silhouette*/
    background-position: center;
    background-size: cover;
  }

  .class-menu {
    flex-direction: column;
    justify-content: flex-end;
  }

  .class-menu li {
    transform: rotate(0) translateY(0) rotate(0);
  }

  .class-menu li:nth-child(1) {
    transform: rotate(0) translateY(0) rotate(0);
  }

  .class-menu li:nth-child(2) {
    transform: rotate(0) translateY(0) rotate(0);
  }

  .class-menu li:nth-child(3) {
    transform: translateY(0);
  }

  .class-menu li:nth-child(4) {
    transform: rotate(0) translateY(0) rotate(0);
  }

  .class-menu li:nth-child(5) {
    transform: rotate(0) translateY(0) rotate(0);
  }
}
